var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FaieldJobsFilters',{attrs:{"getLogs":_vm.getLogs,"perPage":_vm.perPage}}),_c('v-data-table',{staticClass:"elevation-0 opacityTable mx-15",attrs:{"page":1,"itemsPerPage":_vm.perPage,"hide-default-footer":"","light":"","headers":_vm.headers,"items":_vm.errorFailedJobsList},scopedSlots:_vm._u([{key:"item.failed_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(item.failed_at.split(" ")[0])))]),_c('div',{staticClass:"caption"},[_c('v-icon',{staticClass:"mb-1 font-weight-black",attrs:{"x-small":""}},[_vm._v("schedule")]),_vm._v(" "+_vm._s(item.failed_at.split(" ")[1])+" ")],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1 mr-3",attrs:{"x-small":"","color":"error","dark":""},on:{"click":function($event){return _vm.getMoreDetails(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" error_outline ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Ver detalhes do erro")])])]}}])}),_c('LogErrorDeatil',{attrs:{"logErrorDeatilDilog":_vm.logErrorDeatilDilog,"toogleLogErrorDeatilDilog":_vm.toogleLogErrorDeatilDilog,"jsonError":_vm.jsonError,"stringError":_vm.stringError,"propsSubTitle":"Dados e informações registradas","propsTitle":"Detalhes do erro"}}),_c('v-row',{staticClass:"mx-15 d-flex flex-row-reverse bordTop"},[_c('v-col',{staticClass:"d-flex pr-0",staticStyle:{"max-width":"75px"},attrs:{"cols":"12","sm":"1"}},[_c('v-select',{attrs:{"items":[10, 50, 100]},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('v-col',{staticClass:"pr-1 mb-7 align-end d-flex justify-end col-sm-2 col-12",staticStyle:{"max-width":"110px"},attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"caption"},[_vm._v("Itens por página")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-container',{staticClass:"d-flex flex-row justify-end max-width pr-0"},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":8},model:{value:(_vm.actualPage),callback:function ($$v) {_vm.actualPage=$$v},expression:"actualPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }