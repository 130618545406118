<template>
  <div>
    <FaieldJobsFilters :getLogs="getLogs" :perPage="perPage" />
    <!-- v-if="logsList.length > 0" -->
    <v-data-table
      :page="1"
      :itemsPerPage="perPage"
      hide-default-footer
      light
      :headers="headers"
      :items="errorFailedJobsList"
      class="elevation-0 opacityTable mx-15"
    >
      <template v-slot:item.failed_at="{ item }">
        <div>{{ item.failed_at.split(" ")[0] | formatDate }}</div>
        <div class="caption">
          <v-icon class="mb-1 font-weight-black" x-small>schedule</v-icon>
          {{ item.failed_at.split(" ")[1] }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="btMicro px-1 mr-3"
              @click="getMoreDetails(item)"
              x-small
              color="error"
              dark
              ><v-icon center x-small> error_outline </v-icon></v-btn
            >
          </template>
          <span class="miniTtip">Ver detalhes do erro</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <LogErrorDeatil
      :logErrorDeatilDilog="logErrorDeatilDilog"
      :toogleLogErrorDeatilDilog="toogleLogErrorDeatilDilog"
      :jsonError="jsonError"
      :stringError="stringError"
      propsSubTitle="Dados e informações registradas"
      propsTitle="Detalhes do erro"
    />
    <v-row class="mx-15 d-flex flex-row-reverse bordTop">
      <v-col style="max-width: 75px" class="d-flex pr-0" cols="12" sm="1">
        <v-select v-model="perPage" :items="[10, 50, 100]"></v-select>
      </v-col>
      <v-col
        style="max-width: 110px"
        class="pr-1 mb-7 align-end d-flex justify-end col-sm-2 col-12"
        cols="12"
        sm="2"
        ><span class="caption">Itens por página</span></v-col
      >
      <v-col class="" cols="5">
        <v-container class="d-flex flex-row justify-end max-width pr-0">
          <v-pagination
            v-model="actualPage"
            :length="totalPages"
            :total-visible="8"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MyFilters from "../../../filters/genericFilters";
import FaieldJobsFilters from "./FaieldJobsFilters.vue";
import LogErrorDeatil from "../LogErrorDeatil.vue";
export default {
  watch: {
    actualPage(v, oldV) {
      //console.log("qsqsqs", oldV);
      if (!oldV || !v) {
        return;
      }
      this.getLogs(this.lastParans, v);
    },
  },
  methods: {
    toogleLogErrorDeatilDilog() {
      this.logErrorDeatilDilog = !this.logErrorDeatilDilog;
    },
    getMoreDetails(data) {
      //   console.log("moredet", data.id);
      var obj = {
        url: "/api/failedjobs/getById",
        query: { id: data.id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // let respError = "";
        try {
          //   respError = JSON.parse(response.data);
          this.jsonError = JSON.stringify(response.data, null, 4);
          this.stringError = "";
        } catch (error) {
          this.jsonError = "";
          this.stringError = response.data;
        }
        this.toogleLogErrorDeatilDilog();
      });
    },
    getLogs(dataQuery, page) {
      let complementPag = page ? `/?page=${page}` : "";
      // this.setedPage = page ? true : false;
      this.actualPage = page;
      this.lastParans = { ...dataQuery };
      var obj = {
        url: "/api/failedjobs/getFailedJobs" + complementPag,
        query: dataQuery,
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //console.log("breca!");
        this.errorFailedJobsList = [...response.data.data];
        this.totalPages = response.data.last_page;
        //this.setedPage = true;
        this.actualPage = response.data.current_page;
      });
    },
    getHeightPage() {
      this.windowH = window.innerHeight - 125;
    },
  },
  created() {
    window.addEventListener("resize", this.getHeightPage);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeightPage);
  },
  data() {
    return {
      windowH: "580",
      jsonError: "",
      stringError: "",
      logErrorDeatilDilog: false,
      totalPages: 0,
      perPage: 10,
      actualPage: 0,
      errorFailedJobsList: [],
      headers: [
        { text: "Data do erro", value: "failed_at", sortable: false },
        { text: "Erro", value: "error", sortable: false },
        { text: "Opções", value: "actions", sortable: false },
      ],
    };
  },
  beforeMount() {
    this.getHeightPage();
  },
  components: { FaieldJobsFilters, LogErrorDeatil },
};
</script>

<style>
</style>