var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('LoginHistroyFilter',{attrs:{"getLogs":_vm.getLogs,"perPage":_vm.perPage}}),_c('v-data-table',{staticClass:"elevation-0 opacityTable mx-15",attrs:{"page":1,"itemsPerPage":_vm.perPage,"hide-default-footer":"","light":"","headers":_vm.headers,"items":_vm.errorFailedJobsList},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"maxw-300 pr-3 py-2"},[(item.autentication)?_c('v-icon',{staticClass:"mb-1 mr-2",attrs:{"small":"","color":"primary","dark":""}},[_vm._v(" mdi-account-key ")]):_c('v-icon',{staticClass:"mb-1 mr-2",attrs:{"small":"","color":"red","dark":""}},[_vm._v(" mdi-account-alert ")]),_c('span',[_c('b',[_vm._v(_vm._s(item.status))])]),_c('div',[_c('small',[_vm._v("Msg: "+_vm._s(item.message ? item.message : 'Não há mensagem'))])])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('div',[_c('b',[_vm._v(_vm._s(item.partner_name))])]),_c('div',[_c('small',[_vm._v("Usuário: "),_c('b',[_vm._v(_vm._s(item.name ? item.name : '-'))])])]),_c('div',[_c('small',[_vm._v(_vm._s(item.email ? item.email : '-'))])])])]}},{key:"item.ip",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"maxw-350"},[_c('div',[_c('b',[_vm._v(_vm._s(item.ip))])]),_c('div',[_c('small',[_vm._v(_vm._s(item.device))])])])]}},{key:"item.event_date",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('b',[_vm._v(_vm._s(_vm._f("formatDate")(item.event_date.split(" ")[0])))])]),_c('div',[_c('small',[_vm._v("hora: "),_c('b',[_vm._v(_vm._s(item.event_date.split(" ")[1]))])])])]}}])}),_c('v-row',{staticClass:"mx-15 d-flex flex-row-reverse bordTop"},[_c('v-col',{staticClass:"d-flex pr-0",staticStyle:{"max-width":"75px"},attrs:{"cols":"12","sm":"1"}},[_c('v-select',{attrs:{"items":[10, 50, 100]},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('v-col',{staticClass:"pr-1 mb-7 align-end d-flex justify-end col-sm-2 col-12",staticStyle:{"max-width":"110px"},attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"caption"},[_vm._v("Itens por página")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-container',{staticClass:"d-flex flex-row justify-end max-width pr-0"},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":8},model:{value:(_vm.actualPage),callback:function ($$v) {_vm.actualPage=$$v},expression:"actualPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }