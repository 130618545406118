<template>
    <div>
        <LoginHistroyFilter :getLogs="getLogs" :perPage="perPage" />
        <v-data-table :page="1" :itemsPerPage="perPage" hide-default-footer light :headers="headers"
            :items="errorFailedJobsList" class="elevation-0 opacityTable mx-15">
            <template v-slot:item.status="{ item }">
                <!-- maxw-300 -->
                <div class="maxw-300 pr-3 py-2">
                    <v-icon class="mb-1 mr-2" small v-if="item.autentication" color="primary" dark>
                        mdi-account-key
                    </v-icon>
                    <v-icon class="mb-1 mr-2" small v-else color="red" dark>
                        mdi-account-alert
                    </v-icon>
                    <span><b>{{ item.status }}</b></span>
                    <div><small>Msg: {{ item.message ? item.message : 'Não há mensagem' }}</small></div>
                </div>
            </template>
            <template v-slot:item.name="{ item }">
                <!-- minw-200 -->
                <div class="py-2">
                    <div><b>{{ item.partner_name }}</b></div>
                    <div><small>Usuário: <b>{{ item.name ? item.name : '-' }}</b></small></div>
                    <div> <small>{{ item.email ? item.email : '-' }}</small></div>
                </div>
            </template>
            <template v-slot:item.ip="{ item }">
                <div class="maxw-350">
                    <div><b>{{ item.ip }}</b></div>
                    <div> <small>{{ item.device }}</small></div>
                </div>
            </template>
            <template v-slot:item.event_date="{ item }">
                <div><b>{{ item.event_date.split(" ")[0] | formatDate }}</b></div>
                <div> <small>hora: <b>{{ item.event_date.split(" ")[1]
                            }}</b></small></div>
            </template>
        </v-data-table>
        <v-row class="mx-15 d-flex flex-row-reverse bordTop">
            <v-col style="max-width: 75px" class="d-flex pr-0" cols="12" sm="1">
                <v-select v-model="perPage" :items="[10, 50, 100]"></v-select>
            </v-col>
            <v-col style="max-width: 110px" class="pr-1 mb-7 align-end d-flex justify-end col-sm-2 col-12" cols="12"
                sm="2"><span class="caption">Itens por página</span></v-col>
            <v-col class="" cols="5">
                <v-container class="d-flex flex-row justify-end max-width pr-0">
                    <v-pagination v-model="actualPage" :length="totalPages" :total-visible="8"></v-pagination>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import LoginHistroyFilter from "./LoginHistroyFilter.vue";
export default {
    watch: {
        actualPage(v, oldV) {
            console.log("qsqsqs", oldV);
            if (!oldV || !v) {
                return;
            }
            this.getLogs(this.lastParans, v);
        },
    },
    methods: {
        getLogs(dataQuery, page) {
            //console.log("breca!");
            let complementPag = page ? `/?page=${page}` : "";
            // this.setedPage = page ? true : false;
            this.actualPage = page;
            this.lastParans = { ...dataQuery };
            var obj = {
                url: "/api/v1/subscriptionAPI/autentication/history/get-all" + complementPag,
                query: dataQuery,
                method: "post",
            };
            //console.log(obj);
            this.$store.dispatch("request/doRequest", obj).then((response) => {

                this.errorFailedJobsList = [...response.data.data];
                this.totalPages = response.data.total_page;
                //this.setedPage = true;
                this.actualPage = parseFloat(response.data.current_page);
            });
        },
        getHeightPage() {
            this.windowH = window.innerHeight - 125;
        },
    },
    created() {
        window.addEventListener("resize", this.getHeightPage);
    },
    destroyed() {
        window.removeEventListener("resize", this.getHeightPage);
    },
    data() {
        return {
            windowH: "580",
            totalPages: 0,
            perPage: 10,
            actualPage: 0,
            errorFailedJobsList: [],
            // {
            // "id": 5,
            // "name": "Danilo Teixeira",
            // "email": "danilo-teixeira83@gmail.com",
            // "event_date": "2023-06-20 11:06:15",
            // "status": "Usuario inativo",
            // "subscriber_id": 132,
            // "partner_id": 2,
            // "created_at": "2023-06-20T14:50:15.000000Z",
            // "updated_at": "2023-06-20T14:50:15.000000Z"
            // },
            headers: [
                { text: "Status / Mensagem", value: "status", sortable: false },
                { text: "Provedor / usuário", value: "name", sortable: false },
                // { text: "Provedor", value: "partner_name", sortable: false },
                {
                    text: "IP / Device",
                    align: "start",
                    sortable: false,
                    value: "ip",
                },
                { text: "Data", value: "event_date", sortable: false },
                // { text: "Opções", value: "actions", sortable: false },
            ],
        };
    },
    beforeMount() {
        this.getHeightPage();
    },
    components: {
        LoginHistroyFilter,
    },
};
</script>

<style></style>