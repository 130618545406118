<template>
    <v-form v-model="valid" class="mb-6 mx-15">
        <v-row>
            <v-col style="margin-top: 10px;" cols="12" sm="3">
                <v-menu ref="calendar" v-model="calendar" :close-on-content-click="false" transition="scale-transition"
                    max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field clearable class="rangeInputDate" v-model="dateRangeText" label="Período"
                            hint="Selecione o primeiro e o último dia" persistent-hint v-bind="attrs"
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker class="rangeMode" range v-model="dates" no-title>
                        <div style="width: 100%" class="bordTop d-flex flex-row justify-start">
                            <v-btn class="mt-2" text color="primary" @click="calendar = false">
                                OK
                            </v-btn>
                            <v-btn class="mt-2" text color="primary" @click="calendar = false">
                                Cancelar
                            </v-btn>
                        </div>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="8">
                <v-autocomplete :items="partners" item-text="name" item-value="id" chips multiple v-model="partner_id"
                    label="Selecione os provedores" clearable></v-autocomplete>
            </v-col>
            <!-- <v-col style="max-width: 310px" cols="12" sm="3"><v-select clearable v-model="selectedQueues" :items="queues"
                    label="Filas"></v-select>
            </v-col> -->

            <v-col cols="12" sm="1" class="mt-4">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-2" fab dark x-small color="primary" v-bind="attrs" v-on="on"
                            @click="listLogsByFilters">
                            <v-icon dark small> search </v-icon>
                        </v-btn>
                    </template>
                    <span>Filtrar</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-form>
</template>
  
<script>
export default {
    data() {
        return {
            dates: [],
            calendar: false,
            valid: true,
            partners: [],
            partner_id: ""
            // selectedUser: "",
            // selectedConnection: "",
            // selectedQueues: "",
            // selectedLogType: "",
            // connections: [],
            // queues: [],
            // usersList: [],
            // logTypes: [],
        };
    },
    watch: {},
    mounted() { this.getPartners() },
    methods: {
        getPartners() {
            //   console.log("moredet", data.id);
            var obj = {
                url: "/api/partner/get-all",
                query: {},
                method: "get",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.partners = [...response.data]
            });
        },
        listLogsByFilters() {
            let query = {
                partner_id: this.partner_id,
                per_page: this.perPage,
                start_date: this.dates[0],
                end_date: this.dates[1]
            };
            this.getLogs(query, 0);
        },
        formatRangeDate(listDates) {
            let returnDate = "";
            for (let i = 0; i < listDates.length; i++) {
                let [year, month, day] = listDates[i].split("-");
                returnDate += `${day}/${month}/${year}${i == 0 ? " - " : ""}`;
            }
            return returnDate;
        },
    },
    computed: {
        dateRangeText: {
            // getter
            get: function () {
                return this.formatRangeDate(this.dates);
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.dates = [];
                }
            },
        },
    },
    props: { getLogs: Function, perPage: Number },
};
</script>
  
<style>
.rangeInputDate input {
    font-size: 14px !important;
}

.rangeMode .v-date-picker-table {
    max-height: 210px;
}
</style>