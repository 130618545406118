<template>
  <div class="pl-16 pr-2 mb-3">
    <v-card :min-height="windowH" class="mx-auto mt-3 bgTransparente" max-width="calc(100% - 10px)" elevation="0">
      <v-card-title class="ml-10 pt-4 pb-8 pl-0 black--text"><v-tabs v-model="tab">
          <v-tab>Logs operacionais</v-tab>
          <v-tab>Log failed jobs</v-tab>
          <!-- <v-tab>Logs de autenticação</v-tab> -->
        </v-tabs></v-card-title>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <LogFilters :getLogs="getLogs" :perPage="perPage" />
          <!-- v-if="logsList.length > 0" -->
          <v-data-table :page="1" :itemsPerPage="perPage" hide-default-footer light :headers="headers" :items="logsList"
            class="elevation-0 opacityTable mx-15">
            <template v-slot:item.user="{ item }">
              <div>
                {{ item.user }}
              </div>
              <div class="caption font-weight-black">
                {{ item.description.email }}
              </div>
            </template>
            <template v-slot:item.event="{ item }">
              <div>{{ item.event.split(" ")[0] | formatDate }}</div>
              <div class="caption">
                <v-icon class="mb-1 font-weight-black" x-small>schedule</v-icon>
                {{ item.event.split(" ")[1] }}
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-3" @click="getMoreDetails(item)" x-small
                    :color="item.type == 'ERROR' ? 'error' : 'primary'" dark><v-icon center x-small> error_outline
                    </v-icon></v-btn>
                </template>
                <span class="miniTtip">{{
                  item.type == "ERROR"
                  ? "Detalhes do erro"
                  : "Ver mais detalhes"
                }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <LogErrorDeatil :logErrorDeatilDilog="logErrorDeatilDilog"
            :toogleLogErrorDeatilDilog="toogleLogErrorDeatilDilog" :jsonError="jsonError" :stringError="stringError"
            propsSubTitle="Informações registradas" :propsTitle="propsTitle" />
          <v-row class="mx-15 d-flex flex-row-reverse bordTop">
            <v-col style="max-width: 75px" class="d-flex pr-0" cols="12" sm="1">
              <v-select v-model="perPage" :items="[10, 50, 100]"></v-select>
            </v-col>
            <v-col style="max-width: 110px" class="pr-1 mb-7 align-end d-flex justify-end col-sm-2 col-12" cols="12"
              sm="2"><span class="caption">Itens por página</span></v-col>
            <v-col class="" cols="5">
              <v-container class="d-flex flex-row justify-end max-width pr-0">
                <v-pagination v-model="actualPage" :length="totalPages" :total-visible="8"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <FailedJobs />
        </v-tab-item>
        <!-- <v-tab-item>
          <LoginHistory />
        </v-tab-item> -->
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import MyFilters from "../../filters/genericFilters";
import LogFilters from "./LogFilters.vue";
import LogErrorDeatil from "./LogErrorDeatil.vue";
import FailedJobs from "./failedJobs/FailedJobs.vue";
import LoginHistory from "./loginHistory/LoginHistory.vue";
export default {
  computed: {
    responsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  beforeMount() {
    this.getHeightPage();
  },
  created() {
    window.addEventListener("resize", this.getHeightPage);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeightPage);
  },
  mounted() {
    //this.getLogs();
  },
  watch: {
    actualPage(v, oldV) {
      //console.log("qsqsqs", oldV);
      if (!oldV || !v) {
        return;
      }
      this.getLogs(this.lastParans, v);
    },
  },
  methods: {
    toogleLogErrorDeatilDilog() {
      this.logErrorDeatilDilog = !this.logErrorDeatilDilog;
    },
    getMoreDetails(data) {
      let respError = "";
      data.type == "ERROR"
        ? (this.propsTitle = "Detalhes do Erro")
        : (this.propsTitle = "Detalhes do Log");

      try {
        respError = JSON.parse(
          data.return.split("Retorno da persistencia: ")[1]
        );
        this.jsonError = JSON.stringify(respError, null, 4);
        this.stringError = "";
      } catch (error) {
        this.jsonError = "";
        this.stringError = data.return.split("Retorno da persistencia: ")[1];
      }
      this.toogleLogErrorDeatilDilog();
    },
    getHeightPage() {
      this.windowH = window.innerHeight - 125;
    },
    getLogs(dataQuery, page) {
      let complementPag = page ? `/?page=${page}` : "";
      // this.setedPage = page ? true : false;
      this.actualPage = page;
      this.lastParans = { ...dataQuery };
      var obj = {
        url: "/api/auditlogs/logs" + complementPag,
        query: dataQuery,
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //console.log("breca!");
        this.logsList = [...response.data.data];
        this.totalPages = response.data.last_page;
        //this.setedPage = true;
        this.actualPage = response.data.current_page;
      });
    },
  },
  data() {
    return {
      windowH: "580",
      propsTitle: "",
      tab: null,
      jsonError: "",
      stringError: "",
      logErrorDeatilDilog: false,
      setedPage: false,
      totalPages: 0,
      perPage: 10,
      actualPage: 0,
      logsList: [],
      headers: [
        { text: "Usuário", value: "user", sortable: true },
        { text: "IP", value: "ip_address", sortable: true },
        { text: "Mensagem", value: "description.message", sortable: false },
        {
          text: "Ação",
          value: "description.action",
          sortable: false,
        },
        { text: "Data do evento", value: "event", sortable: false },
        { text: "Opções", value: "actions", sortable: false },
      ],
    };
  },
  components: { LogFilters, LogErrorDeatil, FailedJobs, LoginHistory },
};
</script>

<style></style>