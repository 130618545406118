var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-16 pr-2 mb-3"},[_c('v-card',{staticClass:"mx-auto mt-3 bgTransparente",attrs:{"min-height":_vm.windowH,"max-width":"calc(100% - 10px)","elevation":"0"}},[_c('v-card-title',{staticClass:"ml-10 pt-4 pb-8 pl-0 black--text"},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Logs operacionais")]),_c('v-tab',[_vm._v("Log failed jobs")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('LogFilters',{attrs:{"getLogs":_vm.getLogs,"perPage":_vm.perPage}}),_c('v-data-table',{staticClass:"elevation-0 opacityTable mx-15",attrs:{"page":1,"itemsPerPage":_vm.perPage,"hide-default-footer":"","light":"","headers":_vm.headers,"items":_vm.logsList},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.user)+" ")]),_c('div',{staticClass:"caption font-weight-black"},[_vm._v(" "+_vm._s(item.description.email)+" ")])]}},{key:"item.event",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(item.event.split(" ")[0])))]),_c('div',{staticClass:"caption"},[_c('v-icon',{staticClass:"mb-1 font-weight-black",attrs:{"x-small":""}},[_vm._v("schedule")]),_vm._v(" "+_vm._s(item.event.split(" ")[1])+" ")],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1 mr-3",attrs:{"x-small":"","color":item.type == 'ERROR' ? 'error' : 'primary',"dark":""},on:{"click":function($event){return _vm.getMoreDetails(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" error_outline ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v(_vm._s(item.type == "ERROR" ? "Detalhes do erro" : "Ver mais detalhes"))])])]}}])}),_c('LogErrorDeatil',{attrs:{"logErrorDeatilDilog":_vm.logErrorDeatilDilog,"toogleLogErrorDeatilDilog":_vm.toogleLogErrorDeatilDilog,"jsonError":_vm.jsonError,"stringError":_vm.stringError,"propsSubTitle":"Informações registradas","propsTitle":_vm.propsTitle}}),_c('v-row',{staticClass:"mx-15 d-flex flex-row-reverse bordTop"},[_c('v-col',{staticClass:"d-flex pr-0",staticStyle:{"max-width":"75px"},attrs:{"cols":"12","sm":"1"}},[_c('v-select',{attrs:{"items":[10, 50, 100]},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('v-col',{staticClass:"pr-1 mb-7 align-end d-flex justify-end col-sm-2 col-12",staticStyle:{"max-width":"110px"},attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"caption"},[_vm._v("Itens por página")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-container',{staticClass:"d-flex flex-row justify-end max-width pr-0"},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":8},model:{value:(_vm.actualPage),callback:function ($$v) {_vm.actualPage=$$v},expression:"actualPage"}})],1)],1)],1)],1),_c('v-tab-item',[_c('FailedJobs')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }