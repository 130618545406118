<template>
  <v-form v-model="valid" class="mb-6 mx-15">
    <v-row>
      <v-col style="max-width: 310px" cols="12" sm="3"
        ><v-select
          readonly
          v-model="selectedConnection"
          :items="connections"
          label="Tipo de conexão"
        ></v-select>
      </v-col>
      <v-col style="max-width: 310px" cols="12" sm="3"
        ><v-select
          clearable
          v-model="selectedQueues"
          :items="queues"
          label="Filas"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-menu
          ref="calendar"
          v-model="calendar"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              class="rangeInputDate"
              v-model="dateRangeText"
              label="Período"
              hint="Selecione o primeiro e o último dia"
              persistent-hint
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker class="rangeMode" range v-model="dates" no-title>
            <div
              style="width: 100%"
              class="bordTop d-flex flex-row justify-start"
            >
              <v-btn
                class="mt-2"
                text
                color="primary"
                @click="calendar = false"
              >
                OK
              </v-btn>
              <v-btn
                class="mt-2"
                text
                color="primary"
                @click="calendar = false"
              >
                Cancelar
              </v-btn>
            </div>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="1" class="mt-4">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              x-small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="listLogsByFilters"
            >
              <v-icon dark small> search </v-icon>
            </v-btn>
          </template>
          <span>Filtrar</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      dates: [],
      calendar: false,
      valid: true,
      selectedUser: "",
      selectedConnection: "",
      selectedQueues: "",
      selectedLogType: "",
      connections: [],
      queues: [],
      usersList: [],
      logTypes: [],
    };
  },
  watch: {},
  computed: {
    dateRangeText: {
      // getter
      get: function () {
        return this.formatRangeDate(this.dates);
      },
      // setter
      set: function (newValue) {
        if (!newValue) {
          this.dates = [];
        }
      },
    },

    // dateRangeText() {
    //   //console.log("ppppp", this.dates);
    //   return this.formatRangeDate(this.dates);
    // },
  },
  mounted() {
    this.getConnections();
    this.getQueues();
    // this.getUsers();
    // this.getLogTypes();
  },
  methods: {
    listLogsByFilters() {
      let query = {
        connection: this.selectedConnection,
        queue: this.selectedQueues,
        start_date: this.dates[0],
        end_date: this.dates[1],
        per_page: this.perPage,

        // module: "Administrativo",
        // sub_module: this.selectedQueues,
        // type: this.selectedLogType,
        // start_date: this.dates[0],
        // end_date: this.dates[1],
        // user_name: this.selectedUser,
        // per_page: this.perPage,
      };
      this.getLogs(query, 0);
    },
    formatRangeDate(listDates) {
      let returnDate = "";
      for (let i = 0; i < listDates.length; i++) {
        let [year, month, day] = listDates[i].split("-");
        returnDate += `${day}/${month}/${year}${i == 0 ? " - " : ""}`;
      }

      return returnDate;
    },
    getConnections() {
      var obj = {
        url: "/api/failedjobs/get-connections",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.connections = [...response.data];
        this.connections = [...response.data.map((mod) => mod.connection)];
        this.selectedConnection = this.connections[0];
      });
    },
    getQueues() {
      var obj = {
        url: "/api/failedjobs/get-queues",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.queues = [...response.data.map((mod) => mod.queue)];
        //this.queues = [...response.data];
      });
    },
    // getUsers() {
    //   var obj = {
    //     url: "/api/auditlogs/get-users",
    //     query: null,
    //     method: "get",
    //   };
    //   //console.log(obj);
    //   this.$store.dispatch("request/doRequest", obj).then((response) => {
    //     // console.log(response);
    //     this.usersList = [
    //       ...response.data
    //         .filter((user) => {
    //           if (user.user) return user.user;
    //         })
    //         .map((us) => us.user),
    //     ];
    //   });
    // },
    // getLogTypes() {
    //   var obj = {
    //     url: "/api/auditlogs/get-types",
    //     query: null,
    //     method: "get",
    //   };
    //   //console.log(obj);
    //   this.$store.dispatch("request/doRequest", obj).then((response) => {
    //     // console.log(response);
    //     this.logTypes = [...response.data.map((log) => log.type)];
    //   });
    // },
  },
  props: { getLogs: Function, perPage: Number },
};
</script>

<style>
.rangeInputDate input {
  font-size: 14px !important;
}
.rangeMode .v-date-picker-table {
  max-height: 210px;
}
</style>